<template>
  <div
    class="flex justify-center min-h-screen px-2 pt-2 pb-6 bg-white body-bg md:pt-20 md:px-0"
  >
    <div class="container flex overflow-hidden rounded-md">
      <router-view class="flex-1 overflow-hidden" />
    </div>
  </div>
</template>

<script>
export default {
  created () {
    if(window.location.protocol !== "https:" && process.env.NODE_ENV === "production")
      window.location = "https://" + window.location.hostname + window.location.pathname + window.location.search;

  },
}
</script>